import dynamic from "next/dynamic";

const Layout = dynamic ( () => import ( "_components/templates/layout" ) );

const LayoutWrapper = props => {
	if ( props?.withLayout ) {
		return (
			<Layout>
				{props.children}
			</Layout>
		);
	}

	return props.children;
};

export default LayoutWrapper;
